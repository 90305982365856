
export const blogData = [
    {
        id: 1,
        category: ["EHS Insight", "Concept"],
        blogType: "standard",
        tags: ["Development", "Events", "Media", "Mountains"],
        img: "https://ellisianimages.s3.us-east-2.amazonaws.com/The%20Cost%20of%20Safety%20.png",
        title: "Direct costs of the top 10 most disabling US workplace injuries in 2020 amounted to $52.93 billion.",
        content: "Understanding the business case for improving safety and well-being is critical to companies managing enterprise risks as well as a moral obligation to do whats right for people.",
        author: 1,
        likes: 28,
        comments: 0,
        date: "18 February 2023"
    }
]


export const BlogCategoryData = [
    {
        id: 118,
        category: "LIFESTYLE",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 119,
        category: "SUMMER",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 120,
        category: "COFFEE",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 121,
        category: "FASHION",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    },
    {
        id: 122,
        category: "DRINKS",
        blogType: "category",
        img: "https://via.placeholder.com/600x745",

    }
]

export const authorData = [
    {
        id: 1,
        img: "",
        name: "Statista 2023",
        description: "",
        designation: ""
    },
    {
        id: 2,
        img: "https://via.placeholder.com/125x125",
        name: "Walton smith",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 3,
        img: "https://via.placeholder.com/125x125",
        name: "Bill gardner",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 4,
        img: "https://via.placeholder.com/125x125",
        name: "Vico magistre",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 5,
        img: "https://via.placeholder.com/125x125",
        name: "Coco chanel",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 6,
        img: "https://via.placeholder.com/125x125",
        name: "Mark lamb",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 7,
        img: "https://via.placeholder.com/125x125",
        name: "Lindsey bucki",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 8,
        img: "https://via.placeholder.com/125x125",
        name: "Maya angelou",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
    {
        id: 9,
        img: "https://via.placeholder.com/125x125",
        name: "Jeremy dupont",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque eos perferendis, odio ex deserunt, provident modi voluptatibus esse cum vero numquam saepe ea ipsum error blanditiis nobis magni architecto quasi",
        designation: "co-founder"
    },
]

export const FilterData = [
    {
        title: "All",
        key: "*"
    },
    {
        title: "FASHION",
        key: "fashion "
    },
    {
        title: "TRAVEL",
        key: "travel "
    },
    {
        title: "LIFESTYLE",
        key: "lifestyle "
    },
    {
        title: "BUSINESS",
        key: "business"
    }
]